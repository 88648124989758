import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Freightcharges from "../views/Freightcharges.vue";
import Contactus from "../views/Contact-us.vue";
import Aboutus from "../views/About-us.vue";
import Faqs from "../views/Faqs.vue";
import Terms from "../views/Tos.vue";
import Privacypolicy from "../views/Privacy-policy.vue";
import Shippingpolicy from "../views/Shipping-policy.vue";
import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import Trackparcel from "../views/Tracking.vue";
import Comingsoon from "../views/Coming-soon.vue";
// import Ping from "../components/Ping.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // path: "/",
    // name: "Ping",
    // component: Ping
  },
  {
    path: "/freight-charges",
    name: "freight-charges",
    component: Freightcharges
  },
  {
    path: "/about-us",
    name: "About-us",
    component: Aboutus
  },
  {
    path: "/contact-us",
    name: "Contact-us",
    component: Contactus
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/signup",
    name: "signup",
    component: Comingsoon
  },
  {
    path: "/faq",
    name: "faq",
    component: Faqs
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms
  },
  {
    path: "/track-my-order",
    name: "track-my-order",
    component: Trackparcel
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: Privacypolicy
  },
  {
    path: "/shipping-policy",
    name: "shipping-policy",
    component: Shippingpolicy
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({ el: to.hash, top: 140, behavior: 'smooth',  })
        } else {
          resolve({ top: 0, behavior: 'smooth' })
        }
        
      }, 300)
    })
  }
});

export default router;
