<template>
 
  <div>
    <Header />
    

      
    
      <div
        class="row d-flex mx-auto justify-content-around cta animated animatedFadeInUp fadeInUp hero"
      >
        <div class="col-lg-4 col-12 my-auto mt-5">
          <h1 class="home">Ship from China to Singapore</h1>
          <p class="cta-2">Let us help you order & ship it to you without any extra clicks!</p>
          <form>
            <!-- <div class="form-group">
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter your email here"
              />
            </div> -->
           <a class="btn btn-warning text-uppercase mt-4 py-2 order-lg-last w-100" href="https://wa.me/message/HHHKLTDJPJOMP1?text=Can%I%enquire%about%the%shipping">Get Started</a>
            <p class="mt-3">or <router-link to="/track-my-order">Track my order</router-link></p> 
            
        
          </form>
        </div>
        <div class="col-lg-6 col-12">
          <img src="@/../img/forwardier.svg" />
        </div>
      </div>

      <Logos class="fade-in" />

      <Services :width="browserW" />

      <Strengths />

      <!-- <Video /> -->

      <Reviews />

      <FAQ />

      <Contact />

      <Footer />
    </div>

  
  
</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";
import Logos from "@/components/Logos.vue";
import Services from "@/components/Services.vue";
import Strengths from "@/components/Strengths.vue";
import Video from "@/components/Video.vue";
import Reviews from "@/components/Reviews.vue";
import FAQ from "@/components/Faq.vue";
import Footer from "@/components/Footer.vue";
import Contact from "@/components/Contact.vue";
import { useMeta } from 'vue-meta';



export default {
  name: "Home",
  components: {
    Header,
    Logos,
    Services,
    Strengths,
    Video,
    Reviews,
    FAQ,
    Contact,
    Footer,
  },
  data() {
    return {
      browserW: window.innerWidth,
    };
  },
  setup () {
    useMeta({  })
  }, 
  mounted() {
        const faders = document.querySelectorAll(".fade-in");
   
        const appearOptions = {
            threshold: 0.5
        };

        const appearOnScroll = new IntersectionObserver(function (
          entries,
          appearOnScroll
        ) {
          entries.forEach((entry) => {
              //console.log(entry);
            if (!entry.isIntersecting) {
                return;
            } else {
                entry.target.classList.add("appear");
                //console.log(entry.target);
                appearOnScroll.unobserve(entry.target)
            }
            });
        },
        appearOptions);


        faders.forEach((fader) => {
          appearOnScroll.observe(fader);
        });
        document.title = "Forwardier - Ship from China to Singapore";
      },
};
</script>

<style>

.cta-2 {
  font-size: 1.4rem;
}

.fade-in{
  opacity: 0;
  transition: opacity 250ms ease-in;
}

.fade-in.appear {
  opacity: 1;
}


.home {
  font-weight: 700;
  letter-spacing: .2px;
}

#shipNow {
  background-color: #515151;
  color: white;
  border-radius: 0%;
  padding: 15px;
}

.subheading {
  font-size: 15px;
  font-weight: bold;
}

.desc {
  font-size: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.hero { 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('~@/assets/banner-bg.png') !important;
  overflow: hidden;
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 990px) {
/* Styles */
  /* .cta {
    display: none;
  } */

  .cta{
      display: flex;
      flex-flow: column-reverse;
     }

 
  .hero {
    background-image: url('~@/assets/banner-bg2.png') !important;
  }
}

@media (min-width: 1440px) {
  .home {
    font-size: 60px !important;
  }
}

</style>
