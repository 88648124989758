<template>
    <Header />
    <div class="row d-flex mx-auto justify-content-around mt-5 fade-in">
      <div class="col-lg-6 col-12">
        <h1 id="title" class="mt-3">ABOUT US.</h1>
          <p>Forwardier is established under it's parent company <b>AJS Materials Trading</b> to expand their services to B2C.</p>
          <p>AJS Materials Trading was founded in Singapore and operational since 2013. Main areas in B2B, helping businesses source, purchase and import equipments/materials from China to Singapore. Their clients are mainly in the refrigeration and air-conditioning industry.</p>
          <p>At <b>Forwardier, we provide personalised services for customers</b> who need help with shipping from China to Singapore, having a seamless end-to-end logistic experience with us.</p>
        </div>
        <div class="col-lg-6 col-12 my-auto mx-5 mb-2">
          <img class="abtus" src="@/../img/about-us.png" />
        </div>
        
    </div>


    <div class="container-fluid justify-content-center text-center py-4 fade-in">
      <h2 class="fw-normal">OUR SERVICES.</h2>
      <p class="fs-6">Forwardier provides 3 main forwarding services</p>
    </div>

    <div class="row d-flex mx-auto justify-content-center fade-in">
        <div class="col-lg-12 col-12 d-flex justify-content-center flex-wrap mb-5">
          <div class="card mx-2" style="width: 18rem;">
            <div class="card-body bgc-3 text-white">
              <h5 class="card-title">OrderForMe</h5>
              <p class="card-text">Send us your orders via Whatsapp/Telegram/Wechat, and we handle the logistics. Understand how it works <a class="special-link" href="/ofmguide.png" rel="noopener noreferrer">here</a>.</p>
            </div>
          </div>
          <div class="card mx-2" style="width: 18rem;">
            <div class="card-body bgc-2 text-white">
              <h5 class="card-title">FreightForMe</h5>
              <p class="card-text">Send goods to our warehouse, we will calculate the freight charges and we will handle all logistics from there. Understand how it works <a class="special-link" href="/ffmguide.png" rel="noopener noreferrer">here</a>.</p>
            </div>
          </div>
          <div class="card mx-2 bgc-3" style="width: 18rem;">
            <div class="card-body bgc-1 text-white">
              <h5 class="card-title">SourceForMe</h5>
              <p class="card-text">Let us know what products you are are looking for, we will source for suppliers, provide recommendations and handle the logistic work.</p>
            </div>
          </div>
        </div>     
    </div>



    <div class="row d-flex mx-auto justify-content-around mb-5 osv fade-in">
      <div class="offset-md-1 col-lg-3 col-12">
          <img class="avatar mx-auto d-block" src="@/../img/nicklaus_profile.jpg" />
        </div>
        <div class="col-lg-4 col-12 my-auto mx-5 mt-2">
          <h1 id="title">OUR TEAM.</h1>
          <h6>Nicklaus Neo - Founder</h6>
          <p>As someone who does online shopping frequently on Taobao, I felt that a gap is present in freight forwarding services between Singapore and China.</p>
          <p>Forwardier was created to address the lack of transparency in freight charges, customer service and after-sales support when buying items online from e-commerce platforms like Taobao or JD. </p>
          <p>We strive to deliver a seamless experience for our customers by meeting their logistic needs as well as providing after-sales support when necessary.</p>
        </div>       
    </div>


    
      
    <Footer />

</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { useMeta } from 'vue-meta';

export default {
  name: "aboutus",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      browserW: window.innerWidth,
      webpSupported: true,
    };
  },
  setup () {
    useMeta({ title: 'About Us - Forwardier' })
  }, 
  mounted() {
        const faders = document.querySelectorAll(".fade-in");

        const appearOptions = {
            threshold: 0.5
        };

        const appearOnScroll = new IntersectionObserver(function (
          entries,
          appearOnScroll
        ) {
          entries.forEach((entry) => {
              //console.log(entry);
            if (!entry.isIntersecting) {
                return;
            } else {
                entry.target.classList.add("appear");
                //console.log(entry.target);
                appearOnScroll.unobserve(entry.target)
            }
            });
        },
        appearOptions);


        faders.forEach((fader) => {
          appearOnScroll.observe(fader);
        });
    document.title = "About Us - Forwardier";
  },
  methods: {
    transformImgExt (url) {
      // If webp is supported, transform the url
      if (this.webpSupported) {
        return url.replace(/\.\w{1,5}$/, ".webp");
      } else { // Otherwise, just return the original
        return url;
      }
  }
  },
};
</script>

<style>

.special-link {
  text-decoration: underline !important;
}

.special-link:link {
  color: white !important;
}

.special-link:hover {
   color: white !important;
}

.special-link:visited {
  color: white !important;
}

.osv {
  margin-top: 10vw !important;
}


.card-title {
    font-weight: 800 !important;
}

.card-text {
  font-size: 15px;
}

.bgc-1 {
  background-color: rgb(74, 110, 224);
}

.bgc-2 {
  background-color: rgb(16, 98, 123);
}

.bgc-3 {
  background-color: rgb(13, 128, 101);
}

.avatar {
  vertical-align: middle;
  width: 30vw;
  height: 30vw;
  border-radius: 50%;
  margin-top: -30px;
}

.abtus {
  max-width: 100%;
}

#title {
  font-weight: 800;
}

.home {
  margin-top: 15vh;
}

#shipNow {
  background-color: #515151;
  color: white;
  border-radius: 0%;
  padding: 15px;
}

.subheading {
  font-size: 15px;
  font-weight: bold;
}

.desc {
  font-size: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 990px) {
/* Styles */
  #title {
    margin-top: 14vh;
  }

  .avatar {
    margin-left: auto;
    margin-right: auto;
    width: 50vw;
    height: 50vw;
  }

}

</style>
