<template>
  <div
    class="navbar navbar-expand-lg navbar-light sticky-top bg-light w-100 py-2"
    id="nav"
  >
    <div class="container-fluid">
      <a class="navbar-brand fs-4" href="#">
        <img
          src="../img/logo.png"
          width="70rem"
          height="70rem"
          class="d-inline-block"
        />
        <span class="ps-3 fw-bold">Forwardier</span>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <!-- <span class="navbar-toggler-icon"></span> -->
        <div id="nav-icon">
							<span></span><span></span><span></span><span></span><span></span><span></span>
            </div>
      </button>

      <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav nav-pills">
          <li class="nav-item" v-for="(router, index) in routers" :key="index">
            <router-link
             data-toggle="collapse" data-target=".navbar-collapse.show"
              :to="router.link"
              :class="router.header == 'Get Started' ? 'shipHead' : 'nonShipHead'"
            >
              {{ router.header }}
            </router-link>
           
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header",
  data() {
    return {
      routers: [
        {
          header: "Home",
          link: "/",
        },
        {
          header: "About Us",
          link: "about-us",
        },
        {
          header: "Freight Charges",
          link: "freight-charges",
        },
        {
          header: "FAQ",
          link: "faq",
        },
        {
          header: "Tracking",
          link: "track-my-order",
        },
        {
          header: "Contact Us",
          link: "contact-us",
        },
        // {
        //   header: "Get Started",
        //   link: "signup",
        // },
      ],
      isHovering: false,
      observer: null,
    };
  },
  mounted() {
    document.title = "Forwardier - ";
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#nav {
  padding: 1vw;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  border-bottom: 3px solid #f8ad3e;
}

.nav-item {
  margin: auto 2rem;
}
.navbar {
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));
}

.navbar-toggler {
    border: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 0 !important;
}


.shipHead {
  background-color: #f8ad3e;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 15px;
}

.nonShipHead:hover {
  transition: all 0.1s ease-in-out;
  border-bottom: 3px solid #f8ad3e;
}





/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 990px) {
/* Styles */


.navbar-collapse {
  width: 100%;
  background: white;
  position: fixed;
  z-index: 9;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s;
}
.navbar-collapse ul.navbar-nav {
  position: absolute;
  top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.navbar-collapse ul.navbar-nav li {
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.58, 0.3, 0.005, 1);
  padding: 0;
  margin-bottom: 20px;
}
.navbar-collapse ul.navbar-nav li a {
  color: white;
  font-size: 6.6vmin;
  padding: 0;
}
.navbar-collapse.show {
  min-height: 100vh;
  visibility: visible;
  opacity: 1;
  z-index: 9;
}
.navbar-collapse.show ul li {
  opacity: 1;
}
.navbar-collapse.show ul li:nth-child(1) {
  transition-delay: 0.06s;
}
.navbar-collapse.show ul li:nth-child(2) {
  transition-delay: 0.12s;
}
.navbar-collapse.show ul li:nth-child(3) {
  transition-delay: 0.18s;
}
.navbar-collapse.show ul li:nth-child(4) {
  transition-delay: 0.24s;
}
.navbar-collapse.show ul li:nth-child(5) {
  transition-delay: 0.3s;
}
.navbar-collapse.show ul li:nth-child(6) {
  transition-delay: 0.36s;
}

.navbar-toggler {
  padding: 0;
  z-index: 10;
}

.navbar-brand {
  z-index: 10;
}
.navbar-toggler #nav-icon {
  width: 30px;
  height: 22px;
  position: relative;
  margin: 0 auto;
  z-index: 10;
  transform: rotate(0deg);
  cursor: pointer;
}
.navbar-toggler #nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 50%;
  background: rgb(0, 0, 0);
  opacity: 1;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.navbar-toggler[aria-expanded=false] #nav-icon span:nth-child(even) {
  left: 50%;
}
.navbar-toggler[aria-expanded=false] #nav-icon span:nth-child(odd) {
  left: 0px;
}
.navbar-toggler[aria-expanded=false] #nav-icon span:nth-child(1),
.navbar-toggler[aria-expanded=false] #nav-icon span:nth-child(2) {
  top: 0px;
}
.navbar-toggler[aria-expanded=false] #nav-icon span:nth-child(3),
.navbar-toggler[aria-expanded=false] #nav-icon span:nth-child(4) {
  top: 9px;
}
.navbar-toggler[aria-expanded=false] #nav-icon span:nth-child(5),
.navbar-toggler[aria-expanded=false] #nav-icon span:nth-child(6) {
  top: 18px;
}
.navbar-toggler[aria-expanded=true] #nav-icon span:nth-child(1),
.navbar-toggler[aria-expanded=true] #nav-icon span:nth-child(6) {
  transform: rotate(45deg);
}
.navbar-toggler[aria-expanded=true] #nav-icon span:nth-child(2),
.navbar-toggler[aria-expanded=true] #nav-icon span:nth-child(5) {
  transform: rotate(-45deg);
}
.navbar-toggler[aria-expanded=true] #nav-icon span:nth-child(1) {
  left: 5px;
  top: 7px;
}
.navbar-toggler[aria-expanded=true] #nav-icon span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}
.navbar-toggler[aria-expanded=true] #nav-icon span:nth-child(3) {
  left: -50%;
  opacity: 0;
}
.navbar-toggler[aria-expanded=true] #nav-icon span:nth-child(4) {
  left: 100%;
  opacity: 0;
}
.navbar-toggler[aria-expanded=true] #nav-icon span:nth-child(5) {
  left: 5px;
  top: 12px;
}
.navbar-toggler[aria-expanded=true] #nav-icon span:nth-child(6) {
  left: calc(50% - 5px);
  top: 12px;
}

.shipHead {
  background-color: #ffffff !important;
  border: 0px solid transparent;
  border-radius: 6px;
  padding: 0px !important;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

}

</style>
