<template>
    <Header />
    <div class="row d-flex mx-auto justify-content-around mt-5">
        <div class="col-lg-6 col-12 my-auto mx-5 mb-5">
          <h1 id="title">Shipping Policy</h1>

          <p>Client has to acknowledge and accept that (We) Forwardier is a company providing freight-forwarding services from China to Singapore (i.e. FreightForMe)</p>
          <p>OrderForMe is a service created to help clients with the purchasing and shipping of items from Taobao/1688 platform to Singapore. We are not related to Taobao/1688 or any of its sellers by any means.</p>
          <p>Clients have to read through and accept all the terms stated in this page before engaging our services. </p>
          <ol>
            <li>Lead Time</li>
            <p>Shipping lead time for both air/sea freight stated on our website is just an estimation based on the average shipping duration that does not factor in unforeseeable circumstances like delays due to customs clearance, bad weather or international shortage of vessels during peak periods. As these factors are beyond the control of Forwardier, there will not be any form of compensation for deliveries made beyond the stated shipping lead time.</p>

            <li>Dimensional & Weight Restrictions</li>
              <br>
              <ul>
                <li>Maximum allowable weight for 1 CBM is 500 kg. When this weight is exceeded, the freight charges are subjected to a surcharge based on the following formula:</li>
                <p>Example: Total Volume = 0.80 CBM, Total Weight = 575 kg </p>
                <p>Overweight Surcharge = Chargeable CBM (Minimum 1 CBM) + $13.00 * (Chargeable Weight Rounded up to nearest 50 kg - 500 k) / 50 kg </p>
                <p>Sea Freight =$130.00 + $13.00 * (600 kg - 500 kg) / 50 kg = $156.00</p>
                
                <br>

                <li>Weight Restrictions for orders under 1.00 CBM:</li>
                <p>Weight (kg) stated below refers to the combined actual weight of all your parcels.</p>

                  <ol type="A" class="resizing-font">
                    <li>For orders less than or equals to 0.10 CBM:</li>
                    <ol type="a">
                      <li>More Than or equals to10 kg, surcharge of $5.00</li>
                      <li>More Than or equals to 15 kg, surcharge of $7.50</li>
                      <li>More Than or equals to 20 kg, surcharge of $10.00</li>
                      <li>More Than or equals to 30 kg, surcharge will depend on the final weight</li>
                    </ol>
                    <li>For orders between 0.10 CBM ~ 0.20 CBM:</li>
                    <ol type="a">
                      <li>More Than or equals to 20 kg, surcharge of $5.00</li>
                      <li>More Than or equals to 25 kg, surcharge of $7.50</li>
                      <li>More Than or equals to 30 kg, surcharge of $10.00</li>
                      <li>More Than or equals to 40 kg, surcharge will depend on the final weight</li>
                    </ol>
                    <li>For orders between 0.20 CBM ~ 0.30 CBM:</li>
                    <ol type="a">
                      <li>More Than or equals to 30 kg, surcharge of $5.00</li>
                      <li>More Than or equals to 35 kg, surcharge of $7.50</li>
                      <li>More Than or equals to 40 kg, surcharge of $10.00</li>
                      <li>More Than or equals to 50 kg, surcharge will depend on the final weight</li>
                    </ol>
                    <li>For orders between 0.30 CBM ~ 0.50 CBM:</li>
                    <ol type="a">
                      <li>More Than or equals to 40 kg, surcharge of $5.00</li>
                      <li>More Than or equals to 50 kg, surcharge of $7.50</li>
                      <li>More Than or equals to 60 kg, surcharge of $10.00</li>
                      <li>More Than or equals to 70 kg, surcharge will depend on the final weight</li>
                    </ol>
                    <li>For orders between 0.50 CBM ~ 1.00 CBM:</li>
                    <ol type="a">
                      <li>More Than or equals to 75 kg, surcharge of $20.00</li>
                      <li>More Than or equals to 100 kg, surcharge of $30.00</li>
                      <li>More Than or equals to 150 kg, freight charges will be based on 1 CBM</li>
                    </ol>
                  </ol>

                  <br>
                  <li>For orders under 0.30 CBM, the actual weight/longest dimension of a single package cannot exceed 35 kg/180 cm, as lifting and manoeuvring would require more than 1 manpower</li>
                  <br>
                  <p>*Orders under 0.30 CBM are delivered by only 1 delivery personnel, hence, there will be a surcharge of $15.00 to cover for extra manpower.</p>
                  <br>
                  <li>For orders under 1.00 CBM, the longest dimension of a single package cannot exceed 220 cm. If this dimension is exceeded, there will be a surcharge of $20.00</li>
              </ul>

              <br><li>Delivery Restrictions</li><br>
              <ul>
                <li>Delivery to doorstep is the default mode of delivery which our team at Forwardier will adhere to this so long as your address is lift-accessible (if not located on ground floor) </li>
                <br>
                <ul class="resizing-font">
                  <li>For oversized/overweight items that cannot enter the lift, items will be delivered to the lift lobby at ground floor or any other location deemed feasible for both the delivery personnel and client.</li>
                  <li>If the client wishes to engage our services for delivery up the stairs, pricing will be based on a case-by-case basis depending on the conditions at the premises as well as the weight/dimensions of the items.</li>
                </ul>
                <br>
                <li>Alternative mode of transportation has to be arranged for when oversized/overweight items are unable to fit into our vehicles (Van/14ft Box Lorry) OR when special equipment (crane/scissors lift) is required for the manoeuvering of items</li>
                <br>
                <ul class="resizing-font">
                  <li>Transportation can be arranged by either the client or Forwardier.</li>
                  <li>Client is liable for the additional cost of transportation.</li>
                  <li>Common examples of alternative transportation are Lorry Crane/24ft Lorry.</li>
                </ul>
                <br>
                <li>We are unable to provide delivery to restricted or remote areas like Sentosa Island, Jurong Island and army camps.</li>
                  <ul class="resizing-font">
                    <li>We will inform the client if the address provided is classified under this category.</li>
                  </ul>
              </ul>

              <li class="mt-2">Damaged/Incorrect Items</li>
              <p>In the event that an item has to be sent back to the seller in China for exchange, the cost of freight is to be borne by the client</p>

              <br><p><b>Damaged Items</b></p>
              <ul class="resizing-font">
                <li>Similar to how luggages are handled at the airports, every parcel goes through various counterparties and is handled by multiple facilities before arriving at our warehouse in China. As such, we will not be liable for any damages unless gross negligence is proven to have been committed on the part of Forwardier.  </li>
                <li>Damaged items are rarely seen but are commonly caused by improper packaging used by the sellers/suppliers.</li>
                <ul class="mt-2 mb-2">
                  <li>FreightForMe clients are responsible for communicating with the sellers/suppliers to ensure that their items are packed properly and appropriately for international shipping and export purposes.</li>
                  <li>Forwardier will help OrderForMe clients with the necessary communication, however, we will not be liable if the seller does not abide by our requests.</li>
                </ul>
                <li>Should there be any damages, both OrderForMe and FreightForMe clients are to write in to us via Whatsapp within 24 hours after the last-mile delivery has been completed. The external packaging with the two-way bill intact should be kept for tracking purposes. Photos and videos of both the item and external packaging are to be provided.  </li>
                <ul class="mt-2 mb-2">
                  <li>As every scenario is unique, it will be handled strictly on a case-by-case basis.</li>
                  <li>Forwardier will help OrderForMe clients with the necessary communication, but, we will not be liable should the seller not provide any form of compensation.</li>
                </ul>
              </ul>

                <br><p><b>Incorrect Items</b></p>
              <ul class="resizing-font">
                <li>On occasional instances, especially during peak periods like the yearly 11.11 sales, it is possible to encounter incorrect items like wrong sizing/design/quantity due to the negligence and human error from the seller/supplier. Clients should be mentally prepared when such a problem arises as there is always a risk when ordering overseas. Forwardier will not be liable for any incorrect items sent by the seller/supplier. </li>
                <li>FreightForMe clients are responsible for communicating with the sellers/suppliers when such a problem arises.</li>
                <li>Forwardier will help OrderForMe clients with the necessary communication, but, we will not be liable if the seller does not abide by our requests.</li>
                <li>As every scenario is unique, it will be handled strictly on a case-by-case basis.</li>
              </ul>

            <li class="mt-3">Restricted/Prohibited Items</li>

            <br><ul class="resizing-font">
              <li>Parcels may be checked or scanned for restricted/prohibited items. If your parcels are found to contain restricted/prohibited items, we cannot ship them for you. </li>
              <li>You may need to negotiate with the seller/supplier to apply for a refund or send the parcel to an alternative address.</li>
              <ul class="mt-2 mb-2">
                <li>Do note that a handling fee will be imposed based on a case by case basis.</li>
                <li>Client is liable for the cost of sending the parcel back to the seller/supplier, if applicable</li>
              </ul>
              <li>The client is to ensure that the parcels do not contain any restricted/prohibited items. We are not responsible or liable for any loss due to disposal of such parcels during the customs clearance process in both China/Singapore.</li>
            </ul>

            <li class="mt-3">Insurance</li>

            <br><ul class="resizing-font">
              <li>There is no insurance provided and we are not liable for manufacturing defects or product damages.  </li>
            </ul>

            <li class="mt-3">Customs Inspections</li>

            <br><ul class="resizing-font">
              <li>Customs inspections may occur at random in both China and Singapore. The entire shipping container will be opened up for inspection during this process. Parcels might get opened up and confiscated by the custom authorities at random.  </li>
              <li>It is beyond our control and parcels have high risk of going missing during such events.</li>
            </ul>

            <br><p><b>OrderForMe</b></p>
              <ul class="resizing-font">
                <li>Forwardier will waive the service fee for purchases from the first 5 seller/supplier/shops on Taobao.  </li>
                <li>For purchases from more than 5 seller/supplier/shops, there will be a service fee of $2.50 (subject to GST)</li>
                <ul class="mt-2 mb-2">
                  <li>A service charge of $2.50 per item will be imposed for items that require us to liaise with the individual seller/supplier/shops on Taobao.</li>
                  <ul><li>Common examples where liaising is involved include but not limited to: Customisation of furniture/LED Signages or asking the seller/supplier/shop questions with regards to the product detail and specifications. </li></ul>
                </ul>
                <li>We will process your order soonest within our operating hours (Mon - Sat, 1000hrs - 1800hrs) after payment has been received.</li>
                <li>In the event that any item is out of stock, we will notify the client and arrange for a full refund for that particular item.</li>
                <li>After the seller/supplier/shop sends out your item, it cannot be modified or cancelled.</li>
                <li>The client must accept that there is inherent risk when buying from overseas and the quality and usability of the product might not be as portrayed on the item listing. It is up to the client’s own discretion and judgement on whether the item from the particular seller/supplier/shop is reliable or not. </li>
                <li>We will not be liable if the seller/supplier/shop delivers any wrong items, defective items, missing items, or empty packages. However, it is our responsibility to provide assistance to recover the lost items, provided that you are aware that we do not guarantee any kind of monetary compensation or refund.</li>
                <li>We cannot approve returns or refunds on behalf of the seller/supplier/shop. All exchanges, cancellations, and refunds are subject to the seller/supplier/shop’s approval as well as their return and exchange policies. We recommend that you read and familiarise yourself with the seller/supplier/shop’s return and exchange policies.</li>
                <li>Any disputes and claims are subjected to the restrictions and requirements of the seller/supplier/shop’s return and exchange policies. </li>
                <li>o	In the event that an item has to be sent back to the seller in China for exchange, the cost of freight is to be borne by the client.</li>
              </ul>


            <br><p><b>FreightForMe</b></p>
              <ul class="resizing-font">
                <li>Forwardier will not be responsible for any loss or damages caused to your parcels in the event that the unique shipping mark with your code is absent.</li>
                <li>The client is to provide us with the necessary invoices and packing list for their items before we are able to process their order and arrange for shipping.</li>
                <li>We have the sole discretion to reject your parcel(s) if the unique shipping mark with your code is absent, or when it requires cash-on-delivery/self-collection without mutual consent.</li>
                <li>We will not be liable if the seller/supplier/shop delivers any wrong items, defective items, missing items, or empty packages.</li>
                <li>In the event that an item has to be sent back to the seller in China for exchange, the cost of freight is to be borne by the client.</li>
              </ul>

          </ol>
          
        </div>
        
    </div>

      
    <Footer />


</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { useMeta } from 'vue-meta';

export default {
  name: "shipping-policy",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      
    }
  }, 
  setup () {
    useMeta({ title: 'Shipping Policy' })
  }, 
  mounted() {

  },
}
</script>

<style scoped>

.resizing-font {
  font-size: 0.9rem; 
  font-weight: 300;
}

</style>
