<template>
            <div class="row footer">
               
                <div class="col-md-4 mx-5 my-4 py-1 justify-content-center footer-3">
                  <div class="footer-brand d-inline-block">
                    <img src="../img/logo.png" width="70rem" height="70rem" class="d-inline-block my-3"/>
                  </div>
                  
                    <p><a href="/terms">Terms & Conditions</a></p>
                    <p><a href="/shipping-policy">Shipping Policy</a></p>
                    <p><a href="/privacy-policy">Privacy Policy</a></p>
                    <p><a href="/faq">FAQ</a></p>
                </div>

                <div class="col-md-2 offset-md-4 mx-5 my-4 py-1 justify-content-center footer-2 mt-5">
                    <p class="font-weight-bold my-3 footer-head">Office Number</p>
                    <p>+65 9736 6507</p>
                    <a href="https://wa.me/message/HHHKLTDJPJOMP1?src=qr" target="_blank" rel="noopener noreferrer"><img class="w-25" src="@/../img/whatsapp-qr-white.png"></a> 
                    <p class="font-weight-bolder footer-head mt-3">Sales/Support</p>
                    <p>contactus@forwardier.com</p>
                </div>

                <div class="col-md-3 offset-md-2 mx-5 my-4 py-1 justify-content-center footer-2 mt-5">
                    <p class="font-weight-bold my-3 footer-head">Our office</p>
                    <p>38 Defu Lane 10, #04-01, Singapore 539215</p>
                    <p class="font-weight-bolder footer-head">Opening hours</p>
                    <p>Mon to Sat: 0900 - 1800 hrs</p>
                </div>
            </div>
            <div class="row footer">
                <div class="col-md-4 mx-5 justify-content-center text-white">
                  <a href="https://www.instagram.com/forwardier/" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-instagram icon-space"></i></a>
                  <a href="https://www.facebook.com/Forwardier" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-facebook icon-space"></i></a>
                  <a href="https://t.me/forwardier/" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-paper-plane icon-space"></i></a>
                  <a href="https://wa.me/message/HHHKLTDJPJOMP1?src=qr" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-whatsapp icon-space"></i></a>
                </div>
                <div class="col-md-4 d-flex justify-content-center text-white">
                    <p class="copyright-footer">Copyright © 2021 Forwardier | UEN: 53231200K </p>
                </div>
            </div>

       
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>

<style scoped>
.footer {
  background-color: #333652;
}

.footer-2,
.footer-3 {
    font-size: 16px;
    color: #fff;
}

a:link {
    color: #fff;
    text-decoration: none;
}

a {
    color: #fff !important;
}

.icon-space {
  margin-right: 1.5rem;
}

.footer-head {
  color: #f8ad3e;
  font-weight: 800;
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 720px) {
/* Styles */
  .copyright-footer {
    margin-top: 2px;
    font-size: 13px;
    margin-left: 50px;
  }
}
</style>
