<template>
    <Header />
    <div class="row d-flex mx-auto justify-content-around mt-5 animated animatedFadeInUp fadeInUp">
      <div class="col-lg-6 col-12">
          <img class="abtus" src="@/../img/sammy-41.png" />
        </div>
        <div class="col-lg-6 col-12 my-auto mx-5 mb-5">
          <h1 id="title">Frequently Asked Questions</h1>
          <p>Where we answers your doubts</p>
          <div class="faqs-container" v-for="(each, index) of faq" :key="index">
          <div class="faq" >
            <p class="faq-title">
              {{each.heading}}
            </p>
            <p class="faq-text">
              <p v-html="each.answer"></p>
            </p>
            <button class="faq-toggle">
              <i class="fas fa-chevron-down"></i>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        </div>
        
    </div>

      
    <Footer />

</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { useMeta } from 'vue-meta';

export default {
  name: "faqs",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      faq: [
        {
          heading: 'What are your shipping charges like?',
          answer:
            'Sea freight starts from $8.00 <br> Air freight starts from $15.00. <br> You can find more details <a href="/freight-charges" target="_blank" rel="noopener noreferrer">here</a>.',
        },
        {
          heading: 'What is the Sea Freight shipping lead time?',
          answer:
            'After the items arrive at our China warehouse, it takes around 2 - 3 weeks to deliver to your address.',
        },
        {
          heading: 'What is the Air Freight shipping lead time?',
          answer:
            'After the items arrive at our China warehouse, it takes around 4 - 7 working days to deliver to your address.',
        },
         {
          heading: 'What is the mode of payment?',
          answer:
            'Paynow/Paylah to our UEN: 53231200K (AJS Materials Trading) <br> Bank Transfer/Deposit to DBS Account No.: 015-903532-8 (Current)',
        },
        {
          heading: 'Do you provide insurance for shipping?',
          answer:
            'Unfortunately, we do not provide insurance for your items.',
        },
        {
          heading: 'What happens if any of my items goes missing?',
          answer:
            'Contact us and we will revert ASAP. If the item was misplaced by us, we will provide a refund based on the declared amount.',
        },
        {
          heading: 'How do I proceed for FreightForMe?',
          answer:
            'Drop us a Whatsapp and we will send you the address to our China warehouse which contains a code unique to every client. <br> A detailed illustration can be found <a href="/ffmguide.png" rel="noopener noreferrer">here</a>',
        },
        {
          heading: 'How do I proceed for OrderForMe?',
          answer:
            'Send us the links to the items you wish to purchase, along with a screenshot of the item specifications (i.e. Colour/Size/Design). <br> A detailed illustration can be found <a href="/ofmguide.png" rel="noopener noreferrer">here</a>',
        },
        {
          heading: 'Are there additional fees for OrderForMe?',
          answer:
            'Service fees for the first 5 merchants are waived off. There will be a service fee of $2.50 SGD for purchases that exceeds 5 merchants. Items that require customization and liaising with the merchants are subjected to a service fee on a case-by-case basis (i.e. curtains, custom-made furniture, LED signages)',
        },
        {
          heading: 'Are there storage fees?',
          answer:
            'Mentioned below applies only for small parcels with a combined volume of under 0.10CBM. </br>Store your goods in our China warehouse for up to 3 weeks with no additional fees. </br>Store your goods in our Singapore warehouse for up to 1 week with no additional fees. </br>Contact our sales team for storage fees if your items exceed 0.10CBM.',
        },
        {
          heading: 'Can I opt for self-collection of my items?',
          answer:
            'Yes, we do allow for self-collection <br> Freight charges will be adjusted accordingly <br> Address for self-collection: 38 Defu Lane 10 #04-01 Singapore 539215<br>Operating hours: Mon - Sat, 10am - 6pm',
        },
        {
          heading: 'Are there any items that are prohibited?',
          answer:
            'Some of the commonly prohibited items include alcohol, cigarettes, medicine, plants, soil, seeds, livestock, toys shaped like weapons (guns/swords) <br> This list is not exhaustive and a more detailed list can be found <a href="https://www.customs.gov.sg/businesses/importing-goods/controlled-and-prohibited-goods-for-import" target="_blank" rel="noopener noreferrer">here</a>',
        },
      ],
    }
  }, 
  setup () {
    useMeta({ title: 'Frequently Asked Questions' })
  }, 
  mounted() {
    const toggles = document.querySelectorAll('.faq-toggle');

    toggles.forEach(toggle => {
      toggle.addEventListener('click', () => {
        toggle.parentNode.classList.toggle('active');
      });
    });

  },
}
</script>

<style scoped>

.faqs-container {
  margin: 0 auto;
  max-width: 800px;
  /* width: 40vw; */
  display: block;
}

.faq {
  background-color: transparent;
  border: 1px solid #9fa4a8;
  border-radius: 10px;
  padding: 30px;
  position: relative;
  overflow: hidden;
  margin: 20px 0;
  transition: 0.3s ease;
}

.faq.active {
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
}


.faq.active::before {
  color: #2408a1;
  top: -10px;
  left: -30px;
  transform: rotateY(180deg);
}

.faq-title {
  margin: 0 35px 0 0;
  font-weight: bold;
}

.faq-text {
  display: none;
  margin: 30px 0 0;
}

.faq.active .faq-text {
  display: block;
}

.faq-toggle {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0;
  position: absolute;
  top: 30px;
  right: 30px;
  height: 30px;
  width: 30px;
}

.faq-toggle:focus {
  outline: none;
}

.faq.active .faq-toggle {
  background-color: #9fa4a8;
}

.faq-toggle .fa-times {
  display: none;
}

.faq.active .faq-toggle .fa-times {
  display: block;
}

.faq-toggle .fa-chevron-down {
  color: #83888e;
}

.faq.active .faq-toggle .fa-chevron-down {
  display: none;
}
</style>
