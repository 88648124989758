<template>
    <Header />


    <div class="container justify-content-center py-5 fade-in" id="reviews">
      <div class="row">
        <div class="col-md-6 mt-5">
          <img src="@/../img/sammy-42.png" style="max-width: 100%;" />
        </div>
        <div class="col-md-6 shipping-charges text-white">
          <TabsWrapper>
            <Tab title="Small Parcels (Sea)" img="fa-solid fa-shirt">
              <p>Charge by Actual/Volumetric weight (kg) <b style="color: orange;">depending on whichever is higher.</b> <br> Suitable for clients who order small sized items in low quantities.</p>
              <table class="table text-white">
                <thead>
                  <tr>
                    <th class="text-center" style="color: orange;" colspan="4" scope="col">Small Items (0 - 30 Kg)</th>
                  </tr>
                  <tr>
                    <th scope="col">Combined Weight (kg)</th>
                    <th scope="col">First 2 kg</th>
                    <th scope="col">Next 1 kg</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Rates (SGD)</th>
                    <td>$8.00</td>
                    <td>$2.20</td>
                  </tr>
                </tbody>
              </table>
              <p>Updated as of. Jan 2023 <br> Rates are inclusive of delivery to 1 address. <br> Cost of items are subject to prevailing GST. </p>
              
            </Tab>
            <Tab title="Large Parcels (Sea)" img="fa-solid fa-box">
              <p>Charge by Cubic Metres (CBM), the unit of measurement used to indicate the volume occupied by your parcels. Suitable for clients who <b style="color: orange;">order large/bulky items OR a mixture of small/large sized items. </b></p>
              <table class="table text-white">
                <thead>
                  <tr>
                    <th class="text-center" style="color: orange;" colspan="4" scope="col">Large Items (from 0.10 CBM)</th>
                  </tr>
                  <tr>
                    <th scope="col">CBM</th>
                    <th scope="col">First 0.10</th>
                    <th scope="col">0.10 ~ 0.20</th>
                    <th scope="col">0.20 ~ 0.30</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Rates (SGD)</th>
                    <td>$25.00</td>
                    <td>$25.00 + $1.60 per 0.01 CBM</td>
                    <td>$41.00 + $1.40 per 0.01 CBM</td>
                  </tr>
                </tbody>
              </table>
              <br>
              <table class="table text-white">
                <thead>
                  <tr>
                    <th scope="col">CBM</th>
                    <th scope="col">0.30 ~ 0.50</th>
                    <th scope="col">0.50 ~ 0.80</th>
                    <th scope="col">0.81 ~ 1.00</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Rates (SGD)</th>
                    <td>$55.00 + $1.20 per 0.01 CBM</td>
                    <td>$79.00 + $1.35 per 0.01 CBM</td>
                    <td>$130.00</td>
                  </tr>
                </tbody>
              </table>

              <table class="table text-white">
                <thead>
                  <tr>
                    <th class="text-center" style="color: orange;" colspan="4" scope="col">Large Parcels (from 1.00 CBM)</th>
                  </tr>
                  <tr>
                    <th scope="col">CBM</th>
                    <th scope="col">1.00 CBM onwards</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Rates (SGD)</th>
                    <td>$130.00 + $1.30 for every 0.01 CBM</td>
                  </tr>
                </tbody>
              </table>

              <p>Updated as of. Jan 2023 <br> Rates are inclusive of delivery to 1 address. <br> Cost of items are subject to prevailing GST. </p>
            </Tab>
            <Tab title="Air Freight" img="fa-solid fa-truck-plane">
               <p>Shorter lead-time as compared to sea freight, 4 - 6 working days. <br> Charge by Actual/Volumetric weight (kg) depending on whichever is higher.  </p>
              <table class="table text-white">
                <thead>
                  <tr>
                    <th class="text-center" style="color: orange;" colspan="4" scope="col">Non-Sensitive Goods</th>
                  </tr>
                  <tr>
                    <th scope="col">Combined Weight (Kg)</th>
                    <th scope="col">Under 10 Kg</th>
                    <th scope="col">11Kg ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Price</th>
                    <td>First 1kg: $15.00 <br> Next 0.5kg: $5.50</td>
                    <td>Every 1kg: $7.60</td>
                  </tr>
                </tbody>
              </table>

              <table class="table text-white">
                <thead>
                  <tr>
                    <th class="text-center" style="color: orange;" colspan="4" scope="col">Sensitive Goods (Liquid / Electronics) </th>
                  </tr>
                  <tr>
                    <th scope="col">Combined Weight (Kg)</th>
                    <th scope="col">Under 10 Kg</th>
                    <th scope="col">11Kg ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Price</th>
                    <td>First 1kg: $15.80 <br> Next 0.5kg: $5.80</td>
                    <td>Every 1kg: $8.50</td>
                  </tr>
                </tbody>
              </table>

              <p>Updated as of. Jan 2023 <br> Rates are inclusive of delivery to 1 address. <br> GST relief is not applicable starting from 2023. Cost of items are subject to prevailing GST. </p>
            </Tab>
          </TabsWrapper>
        </div>
      </div>
    </div>

    <div class="container-fluid justify-content-center text-center py-4 fade-in">
      <h2 class="fw-normal">Identifying the parcel sizes</h2>
      <p class="fs-6">Get onboard and we will handle everything else</p>
    </div>
    

    <div class="row d-flex mx-auto justify-content-center mb-5 fade-in">
        <div class="col-lg-3 col-12 my-auto mx-5 text-center">
          <h1 id="home">Large Parcels</h1>
          <p>Charge by cubic meters (CBM), also known as the total volume occupied by your goods.</p>
          <p>Suitable for clients who order a <b>mixture of bulky/small items like furniture, lights, bicycles, home appliances.</b></p>
        </div>
        <div class="col-lg-4 col-12">
          <img class="mw-100" src="@/../img/large-parcels.png" />
        </div>
    </div>
      

     <div class="row d-flex mx-auto justify-content-center mb-5 fade-in">
        <div class="col-lg-3 col-12 my-auto mx-5 text-center">
          <h1 id="home">Small Parcels</h1>
          <p>Charge by actual/volumetric weight, depending on whichever is higher</p>
          <p>Suitable for clients who order small sized items like <b>clothing, phone covers, water bottles, shoes in small quantities.</b></p>
        </div>
        <div class="col-lg-4 col-12">
          <img class="mw-100" src="@/../img/small-parcels.png" />
        </div>
    </div>


    <Footer />

</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Tab from "@/components/Tab.vue";
import TabsWrapper from "@/components/TabsWrapper.vue";
import { useMeta } from 'vue-meta';

export default {
  name: "freightcharges",
  components: {
    Tab,
    TabsWrapper,
    Header,
    Footer,
  },
  data() {
    return {
     mode: 'dark'
    };
  },
  setup () {
    useMeta({ title: 'Freight Charges' })
  }, 
  methods: {},
  mounted() {
    const faders = document.querySelectorAll(".fade-in");

        const appearOptions = {
            threshold: 0.5
        };

        const appearOnScroll = new IntersectionObserver(function (
          entries,
          appearOnScroll
        ) {
          entries.forEach((entry) => {
              //console.log(entry);
            if (!entry.isIntersecting) {
                return;
            } else {
                entry.target.classList.add("appear");
                //console.log(entry.target);
                appearOnScroll.unobserve(entry.target)
            }
            });
        },
        appearOptions);


        faders.forEach((fader) => {
          appearOnScroll.observe(fader);
        });
    // document.title = "Freight Charges - Forwardier";
  },
};
</script>

<style>

.home {
  margin-top: 15vh;
}


.title-1 {
  font-family: "Sora";
  width: 380px;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 45px;
}

.title-2 {
  font-size: 18px;
  font-weight: 600;
}


.shipping-charges {
  background: #333652;
  padding: 2rem;
  font-family: "Sora";
  font-style: normal;
  color: #ffffff;
  display: inline-block;
}



</style>
