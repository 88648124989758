<template>
  <div>
    <div
      class="container-fluid justify-content-center text-center py-4"
      id="strengths"
    >
      <h3 class="fw-normal">How Forwardier is here to help</h3>
      <p class="fs-6">The extra service we provide you</p>
      <div class="row d-flex justify-content-center">
        <div
          class="col-md-2 col-12 justify-content-center"
          v-for="(each, index) of strengths"
          :key="index"
        >

          <div class="my-3 mx-auto"><img class="w-75" :src="each.imgId" /></div>
          <p class="strengths-word">{{ index }}</p>
          <!-- <p class="desc"> {{index.desc}} </p> -->
        </div>
      </div>
    </div>
<!-- 
    <div
      class="container-fluid justify-content-center text-center py-4"
      id="getStarted"
    >
      <h3 class="fw-normal">How to get started with us</h3>
      <p class="fs-6">Get onboard and we will handle everything else</p>
      <div class="row d-flex justify-content-center">
        <div
          class="col-md-2 col-12 justify-content-center"
          v-for="(each, index) of getStarted"
          :key="index"
        >

          <div class="block my-3 mx-auto"><img :src="each.imgId" /></div>
          <p class="pb-0 mb-0 subheading">STEP {{ index }}</p>
          <p class="pt-0 desc">{{ each.desc }}</p>
        </div>
      </div>
      <a class="btn btn-warning text-uppercase mt-4 py-2 order-lg-last rounded" href="https://wa.me/message/HHHKLTDJPJOMP1?src=qr">Get Started</a>
    </div> -->
  </div>
</template>

<script>
import hasslefree from "@/img/hassle-free.png";
import nohiddencost from "@/img/no-hidden-cost.png";
import customerservice from "@/img/1to1.png";
import doortodoor from "@/img/doortodoor.png";

export default {
  name: "strengths",
  data() {
    return {
      strengths: {
        "Hassle-free Experience": {
          imgId: hasslefree,
          desc: "",
        },
        "No Hidden Costs": {
          imgId: nohiddencost,
          desc: "",
        },
        "1-to-1 Customer Service Representative": {
          imgId: customerservice,
          desc: "",
        },
        "Door-to-door Delivery": {
          imgId: doortodoor,
          desc: "",
        },
      },
      // getStarted: {
      //   1: {
      //     imgId: "",
      //     desc: "Sign up for an account",
      //   },
      //   2: {
      //     imgId: "",
      //     desc: "Book your shipment",
      //   },
      //   3: {
      //     imgId: "",
      //     desc: "Make payment",
      //   },
      //   4: {
      //     imgId: "",
      //     desc: "Track your items",
      //   },
      // },
    };
  },
};
</script>

<style scoped>
.block {
  width: 120px;
  height: 120px;
  background: rgb(201, 201, 201);
}

.strengths-word {
  font-weight: 800;
}
</style>
