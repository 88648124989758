<template>
  <div>
    <!-- <div class="video-section my-4">
      <div class="row">
        <img src="@/img/play.svg" class="video-play" role="button" data-toggle="modal" data-target="#exampleModalCenter" />
        <p class="video-light-text">Building a house</p>
        <p class="video-strong-text">Sofas & Dining are necessities</p>
      </div>
    </div> -->

    <div class="container my-5">
    <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
      <div class="col-lg-7 p-3 p-lg-5 pt-lg-3">
        <h1 class="display-4 fw-bold lh-1">Some Youtube Video on Forwardier</h1>
        <p class="lead">Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most popular front-end open source toolkit, featuring Sass variables and mixins, responsive grid system, extensive prebuilt components, and powerful JavaScript plugins.</p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
          <button type="button" class="btn btn-primary btn-lg px-4 me-md-2 fw-bold">Primary</button>
          <button type="button" class="btn btn-outline-secondary btn-lg px-4">Default</button>
        </div>
      </div>
      <div class="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
          <img class="rounded-lg-3" src="bootstrap-docs.png" alt="" width="720">
      </div>
    </div>
  </div>

  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>

<style scoped>
.video-section {
  height: 75vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../img/forwardier.png);
}

.video-play {
  /* width: 123px;
  height: 123px; 
  margin-left: 69px;
  margin-top: 25vw; */
  width: calc(201px - 95px);
  height: 123px;
  margin: calc(418px - 28px) 0 0 calc(58px - 18px);

}

.video-light-text {
  position: absolute;
  color: white;
  font-weight: 100;
  font-size: 14px;
  /* margin-left: calc(10vw + 5vw);
  margin-top: calc(26vw + 1vw); */
  margin: calc(447px - 17px) 0 0 calc(181px - 36px);
}

.video-strong-text {
  position: absolute;
  color: white;
  font-weight: 300;
  font-size: 18px;
  /* margin-left: calc(10vw + 5vw);
  margin-top: calc(26vw + 2.5vw); */
  margin: calc(447px - -1px) 0 0 calc(181px - 36px);
}
</style>
