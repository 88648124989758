<template>
  <div>
    <div class="container justify-content-center py-5" id="reviews">
      <div class="row">
        <div class="col-md-6 mt-5">
          <div class="title-1 mb-5">This is why they loved us!</div>
          <blockquote class="blockquote p-2">
            "Ship with ease! Settle our end-to-end supply chain with Forwardier."
          </blockquote>
          <div class="d-flex align-items-center section">
            <div v-for="logo in logos" :key="logo">
              <img :src="logo" class="logos" alt="logo" />
            </div>
          </div>
        </div>
        <div class="col-md 6 google-reviews">
          <div class="profile">
            <img :src="reviews[counter].profile_image" alt="Avatar" class="avatar"/>
           <div class="contact">
             <div class="name">{{reviews[counter].name}}</div>
             <div class="review-from">{{reviews[counter].from}}</div>
           </div>
            
          </div>
          <div class="review-message">
            <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. In massa
            nulla id pretium. Mattis in gravida diam massa lectus -->
            {{reviews[counter].message}}
          </div>

          <div class="review-bar text-center py-5">
            <div v-show="counter<reviews.length&&counter>0" class="review-control-left" href="" role="button" data-slide="prev" @click="prev()">&#10094;</div>
            <div class="review-count">{{counter+1}}/{{reviews.length}}</div>
            <div v-show="counter < reviews.length-1" class="review-control-right" href="" role="button" data-slide="next" @click="next()">&#10095;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "@/img/naturalcool.png";
import img2 from "@/img/aquarius_avenue.png";

export default {
  name: "reviews",
  data() {
    return {
      logos: [img1, img2],
      ifLast: true,
      ifFirst: false,
      reviews: [
        {
          profile_image: "https://lh3.googleusercontent.com/a-/AOh14Gj55ee45t4FHIcHwUYToVzIMdel4i0Mi5T6SCn_ZA=w36-h36-p-c0x00000000-rp-mo-br100",
          name: "Hitesh Suresh",
          from: "Google Reviews",
          message: "Had a great experience with Forwardier. They spoke to the taobao seller to ensure I got the right size item, and got it delivered to me within a few weeks. Throughout the process, they were very responsive on whatsapp, on hand to answer any queries I had. Will definitely be working with them again."
        },
        {
          profile_image: "https://lh3.googleusercontent.com/a/AATXAJzfUTna0J8sqqmOQ2bLB45DSb97V3_g1EIiI7vG=w36-h36-p-c0x00000000-rp-mo-ba3-br100",
          name: "Anand Subramian",
          from: "Google Reviews",
          message: "Nick is One of the most polite and accomodating forwarder Ive ever met. Helped me source a tonne of things along with conveying my requirements in the best possible way to the Vendors. Will be a regular customer of their service now on."
        }
        ,
        {
          profile_image: "https://lh3.googleusercontent.com/a-/AOh14Ggm8cwp1WOqVeAPLJyePHdYEQg9qm6BFCiTdhEQAg=w36-h36-p-c0x00000000-rp-mo-br100",
          name: "Boon Gee Lim",
          from: "Google Reviews",
          message: "You can't go wrong with forwardier. Fast and efficient. Timely updates and ensure your purchase arrives safely at your doorstep. Shipping rates are very reasonable too. Thanks Nick!!!"
        }
        ,
        {
          profile_image: "https://lh3.googleusercontent.com/a/AATXAJzDRw74qqpfrK-uwguy_-fUhhItBKfmvPjTUUQE=w36-h36-p-c0x00000000-rp-mo-br100",
          name: "Betta Leong",
          from: "Google Reviews",
          message: "Thank you to the young boss Nick for the prompt assistance and speedy responses. I repeatedly engaged Forwardier to ship my customised neon light signboard, company's T-shirt and trophies for the betta competitions I organised. Each time, …"
        }
        ,
        {
          profile_image: "https://lh3.googleusercontent.com/a/AATXAJwn4GFSa7JAZZcMrG8EUZHdzShu_bShw4bDdyHp=w36-h36-p-c0x00000000-rp-mo-br100",
          name: "Hoang Thao",
          from: "Google Reviews",
          message: "Very delighted to approach AJS material trading for Freight forwarding service on my Taobao purchase. From liaising of purchase to delivery from Taobao has been fuss free. Item big, very big or small was well taken care of and they offer assistance to help dismantle the wooden protective planks before delivery too. Thank you so much."
        }
      ],
      counter: 0,
    };
  },
  methods: {
    next: function() { if (this.counter < this.reviews.length) { this.counter++; } },
    prev: function() { if (this.counter > 0) {this.counter--; } }
  }
};
</script>

<style scoped>
.title-1 {
  font-family: "Sora";
  width: 280px;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 45px;
}

blockquote {
  font-family: "Sora";
  font-style: normal;
  width: 450px;
  font-weight: 300;
  font-size: 28px;
  line-height: 45px;
  letter-spacing: 0.05em;
  margin-left: 3rem;
}

.logos {
  max-width: 12rem;
  margin: 2rem 2rem;
}
.logos::after {
  content: "";
  clear: both;
  display: table;
}

.google-reviews {
  background: #333652;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: -30px;
}

.google-reviews {
  padding: 5rem;
  font-family: "Sora";
  font-style: normal;
  color: #ffffff;
  display: inline-block;
}

.profile > .contact {
  display: inline-block;
  padding: 10px 0 32px 20px;
}

.profile > .contact > .name {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.05em;
  display: block;
}

.profile > .contact > .review-from {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  display: block;
}

.google-reviews > .review-message {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  padding-top: 2rem;
}

.review-bar {
  position: relative;
  margin-top: 40px;;
}

.review-count {
  left: 0;
  right: 0;
  font-size: 36px;
}

.review-control-left, .review-control-right  {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.review-control-right {
  position: absolute;
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 720px) {
/* Styles */
  blockquote, .logos {
    margin: 0 !important;
  }

  blockquote {
    max-width: 100%;
  }
}

</style>
