<template>
    <Header />

    <div class="row d-flex mx-auto justify-content-center mt-5 mb-5 cta fade-in">
        <div class="col-lg-4 col-12 my-auto mx-5">
          <h3 class="title-3">Where?</h3>
          <p class="desc-3"><a href="https://goo.gl/maps/CgSc9gs3mLm3ZLGk9">38 Defu Lane 10 #04-01  <br>Singapore 539215</a></p>
          <h3 class="title-3">When?</h3>
          <p class="desc-3">Mon to Sat, 0900 - 1800hrs</p>
          <h3 class="title-3">Get in touch</h3>
          <p class="desc-3"><b>Email us:</b> <a href="mailto:contactus@forwardier.com">contactus@forwardier.com</a></p>
          <p class="desc-3"><b>Call / Whatsapp:</b> <a href="https://wa.me/6597366507">+65 9736 6507</a></p>
          <a href="https://wa.me/message/HHHKLTDJPJOMP1?src=qr" target="_blank" rel="noopener noreferrer"><img class="w-25" src="@/../img/whatsapp-qr.png"></a> 
          <a href="https://www.facebook.com/Forwardier" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-facebook mx-2" style="font-size: 3rem;"></i></a>
          <a href="https://www.instagram.com/forwardier/" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-instagram-square mx-2" style="font-size: 3rem;"></i></a>
          <a href="https://t.me/forwardier/" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-telegram mx-2" style="font-size: 3rem;"></i></a>
        </div>
        <div class="col-lg-4 col-12">
            <!-- <img src="@/../img/location-map.png" style="max-width: 100%;" /> -->
          <iframe
          width="100%"
          height="100%"
          frameborder="0" style="border:0; max-wdith: 100%;"
          referrerpolicy="no-referrer-when-downgrade"
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBpykuqrMn-PHXRAYo7fHanm12r6958qWM&q=Forwardier+38+Defu+Lane+10,+#04-01,+Singapore+539215&center=1.3521,103.8914&zoom=18"></iframe>

        </div>
    </div>

    <Footer />
</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { useMeta } from 'vue-meta';

export default {
  name: "contactus",
  components: {
    Header,
    Footer,
  },
  setup () {
      useMeta({ title: 'Contact Us' })
    },  
  data() {
    return {
      browserW: window.innerWidth,
    };
  },
  setup () {
    useMeta({ title: 'Contact Us' })
  }, 
  mounted() {
    const faders = document.querySelectorAll(".fade-in");

        const appearOptions = {
            threshold: 0.5
        };

        const appearOnScroll = new IntersectionObserver(function (
          entries,
          appearOnScroll
        ) {
          entries.forEach((entry) => {
              //console.log(entry);
            if (!entry.isIntersecting) {
                return;
            } else {
                entry.target.classList.add("appear");
                //console.log(entry.target);
                appearOnScroll.unobserve(entry.target)
            }
            });
        },
        appearOptions);


        faders.forEach((fader) => {
          appearOnScroll.observe(fader);
        });

  },
};
</script>

<style>
a:link {
  color: black;
  text-decoration: none;
}

a:visited {
  color:black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: underline;
}

p,
span {
  font-family: "Red Hat Display", sans-serif;
}

.home {
  margin-top: 15vh;
}

#shipNow {
  background-color: #515151;
  color: white;
  border-radius: 0%;
  padding: 15px;
}

.subheading {
  font-size: 15px;
  font-weight: bold;
}

.desc {
  font-size: 15px;
}

.title-3 {
  font-size: 3rem;
  margin: 20px 0 20px 0;
  color: #d48d22;
}

.desc-3 {
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 600;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 990px) {
/* Styles */
  .cta {
    display: none;
  }
}

</style>
