<template>
    <Header />
    <div class="row d-flex mx-auto justify-content-center mt-5">
        <div class="col-lg-6 col-12 d-block text-center">
          <img class="abtus" src="@/../img/comingsoon.png" />
        </div>
        <div class="offset-md-1 col-lg-6 col-12 my-auto mx-5 mb-2 text-center">
          <h1 id="title">Coming Soon</h1>
          <p>We are constantly upgrading our services to serve you better!</p>
          
        </div>
        
    </div>

      
    <Footer />

</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "tos",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      
    }
  }, 
  mounted() {
   document.title = "Coming Soon - Forwardier";
  },
}
</script>

<style scoped>

</style>
