<template>
   <div class="tabs">
        <ul class="tabs__header">
            <li 
            v-for="title, index in tabTitles" 
            :key="title" 
            :class="{ selected: title == selectedTitle}"
            @click="selectedTitle = title">
                <i style="font-size: 30px;" :class="tabClass[index]"></i><br>
                {{ title }}
            </li>
        </ul>
        <slot />
   </div>
</template>

<script>

import { provide, ref } from 'vue';

export default {
    setup(props, { slots }) {
        const tabTitles = ref(slots.default().map((tab) => tab.props.title))
        const tabClass = ref(slots.default().map((tab) => tab.props.img))
        const selectedTitle = ref(tabTitles.value[0])

        provide("selectedTitle", selectedTitle)
        return {
            tabClass,
            selectedTitle,
            tabTitles,
        }
    },
}
</script>

<style scoped>

.tabs {
    /* margin-top: -10%; */
}

.tabs__header {
    margin-bottom: 10px;
    list-style: none;
    padding: 0;
    display: flex;
}

.tabs__header li {
    width: 200px;
    text-align: center;
    color: black;
    padding: 28px 20px;
    margin-right: 10px;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.4s all ease-out;
}

.tabs__header li.selected {
    background-color: #f8ad3e;
    color: black;
}

</style>