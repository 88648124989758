<template>
  <div id="contact-us">
    <div class="contact-section mt-5 fadein">
      <iframe
  width="100%"
  height="100%"
  frameborder="0" style="border:0"
  referrerpolicy="no-referrer-when-downgrade"
  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBpykuqrMn-PHXRAYo7fHanm12r6958qWM&q=Forwardier+38+Defu+Lane+10,+#04-01,+Singapore+539215&center=1.3521,103.8914&zoom=18"></iframe>

    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {};
  },
};
</script>

<style scoped>
.contact-section {
  height: 75vh;
}

</style>
