<template>
    <Header />
    <div class="row d-flex mx-auto justify-content-around mt-5 animated animatedFadeInUp fadeInUp">
      <div class="col-lg-6 col-12">
          <img class="abtus" src="@/../img/sammy-43.png" />
        </div>
        <div class="col-lg-6 col-12 my-auto mx-5 mb-5">
          <h1 id="title">Track your order</h1>
          <p>Enter your order number to track your order.</p>

          <div class="alert alert-danger" v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul>
                        <li v-for="(error, index) in errors" v-bind:key="index">{{ error }}</li>
                    </ul>
                </div>
          <form class="row g-3" @submit.prevent="handleSubmit" method="GET">
            
            <div class="col-md-9">
              <input type="text" class="form-control" placeholder="Enter order ID" v-model="orderID" required/>
              <p class="text-secondary mt-1 subtitle-text">Order ID is case sensitive</p>
            </div>
            <div class="col-md-3">
              <button type="submit" class="btn btn-primary mb-3">Track Order</button>
            </div>
           
          </form>

          <div class="table-responsive">
          <table class="table" v-if="XPathResult.length">
          <thead>
            <tr>
              <th scope="col">Order ID</th>
              <th scope="col">Tracking Number</th>
              <th scope="col">Status</th>
              <th scope="col">Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(each, index) of XPathResult" :key="index">
              <th scope="row">{{ each.OrderID }}</th>
              <td>{{ each.TrackingID }}</td>
              <td>{{ each.Status }}</td>
              <td>{{ each.Remarks }}</td>
            </tr>
          </tbody>
        </table>
      </div>
        </div>
        
    </div>
    <Footer />

</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { useMeta } from 'vue-meta';
import axios from "axios";

export default {
  name: "Trackparcel",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      XPathResult: [],
      orderID: "",
      errors: [],
    }
  }, 
  setup () {
    useMeta({ title: 'Track your parcel' })
  }, 
  methods: {
    handleSubmit() {
      axios.get(encodeURI('https://v1.nocodeapi.com/daerol/google_sheets/VEmhiRlhSIwxHpSt/search?tabId=Sheet1&searchKey=OrderID&searchValue='+this.orderID))
      .then((res) => {
        this.errors = [];
        this.XPathResult = res.data;
        // console.log(this.XPathResult);

        if (res.data == 0) {
          this.errors.push("Order ID not found");
        }
      })
      .catch((err) => {
        console.log(err);
      })

    }
    
  },
}
</script>

<style scoped>
.table {
  overflow-x: scroll;
}

.subtitle-text {
  font-size: 13px;
}
</style>
