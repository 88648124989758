import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueGtag from "vue-gtag";
import { createMetaManager } from 'vue-meta';



createApp(App)
    .use(store)
    .use(router)
    .use(VueGtag, { config: { id: "G-W5EJ96P223" } })
    .use(createMetaManager())
    .mount("#app");
