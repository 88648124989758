<template>
    <Header />
    <div class="row d-flex mx-auto justify-content-center mt-5">
        <div class="col-lg-6 col-12 my-auto mx-5 mb-5">
          <h1 id="title">Terms & Conditions</h1>
          <p>Welcome to <b>Forwardier!</b> </p>
          
          <p><b>Forwardier</b>, a service provider established under AJS Materials Trading and hereinafter referred to as (“Forwardier”, “we”, “us”, “our” or “company”), would like to thank you hereinafter referred to as (“you”, “customer”, “client”) for visiting our website <b>forwardier.com</b></p>
          <ol>
            <li>Acknowledging and Accepting of Terms and Policies</li>
            <p>The terms and conditions, shipping policy and privacy policy listed on our site governs your access and use of our services, which shall form a legally binding and enforceable contract between you and us. The terms and conditions were last updated as of June 2022 and may be updated and/or changed from time to time. We reserve the right, at our sole and absolute discretion, to change, modify, add or remove portions of these terms at any time without notice and, unless otherwise indicated, such changes will become effective immediately. Any amendments will be announced online on our website. You are responsible for reviewing the terms and conditions each time you wish to engage in any of our services.
              By engaging in our services, you agree to be bound by all these terms. If you decide not to be bound by any of these terms, you are not allowed to use our services.</p>
            
            <li>Personal Information</li>
            <p>You agree that we may collect, hold, use and transfer your personal data in accordance with the Privacy Policy.</p>
            
            <li>Shipping Liabilities</li>
            <p>The customer is liable and solely responsible for the declaration of contents and value of each package accurately for duty and tax payments. We are not liable for any false/inaccurate declarations made to the value/type of each package which includes undeclared, controlled or prohibited items. There will be no compensation and the customer will bear the consequences in the event that your parcels are confiscated. Customers are also solely liable for any penalties issued by the Customs Authorities. Shipping fees and any charges associated are not refundable.</p>
            <p>It is the customer’s responsibility to ensure that adequate packaging is used to keep their items well-protected during the entire transportation process from when the parcel first leaves the supplier’s warehouse, up till its arrival at your designated address. Using the appropriate materials for the packaging of goods allow for the items to stay intact and arrive safely. Proper packaging also helps to protect the goods from damages which includes, but not limited to, drop, vibration, indiscriminate stacking, rain, seawater, and moisture. We are not responsible for loss or damage caused by force majeure or any other cause beyond our control.</p>
            <p>For fragile or irregular shapes goods, we are unable to accept liability for any amount or type of damage that might arise during the shipment process due to the involvement of various counterparts which consists of but not limited to courier, freight operator, customs authorities or any other circumstances that may occur during the entire shipment process, unless gross negligence is proven to have been committed on the part of Forwardier.</p>
            <p>All complaints and claims must be submitted in writing to us within 24 hours of delivery of the package. We will not be liable for customers failing to do so within the stipulated time-frame.</p>
            
            <li>Payment</li>
            <p>Payment in Advance (PIA) is the default payment term used for all customers where payment has to be made before we deliver your goods.</p>
            <p>For customers with a net 30 payment term, they are responsible to ensure that all invoices are paid within 30 days. Overdue payment with an interest charge of 2% per month will be charged.</p>
            <p>Payment can be made via Paynow, bank transfer via internet banking/ATM.</p>
          </ol>
        
        </div>
        
    </div>

      
    <Footer />


</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { useMeta } from 'vue-meta';

export default {
  name: "tos",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      
    }
  },
  setup () {
    useMeta({ title: 'Terms & Conditions - Forwardier' })
  }, 
  mounted() {
   document.title = "Terms & Condition - Forwardier";
  },
}
</script>

<style scoped>

</style>
