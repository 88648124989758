<template>
  <div>
    <div
      class="container-fluid justify-content-center text-center py-4"  id="faq"
    >
      <h3 class="fw-normal">Frequently Asked Questions</h3>
      <p class="fs-6 fw-bold">We might have some answers for you.. and more <a href="/faq" target="_blank" rel="noopener noreferrer"> here</a></p>
      <div class="row d-flex justify-content-center">
        <div class="col-md-12 justify-content-center">
        <div class="faqs-container" v-for="(each, index) of faq" :key="index">
          <div class="faq" >
            <p class="faq-title">
              {{each.heading}}
            </p>
            <p class="faq-text">
               <p v-html="each.answer"></p>
            </p>
            <button class="faq-toggle">
              <i class="fas fa-chevron-down"></i>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
          <!-- <p class="desc"> {{index.desc}} </p> -->
        </div>
      </div>
    </div>


    <div
      class="container-fluid justify-content-center text-center py-4"
      id="getStarted"
    >
      <h3 class="fw-bolder">Send us your Taobao links & start shipping</h3>
      <a class="btn btn-warning text-uppercase mt-4 py-2 order-lg-last rounded" href="https://wa.me/message/HHHKLTDJPJOMP1?src=qr">Get Started</a>
    </div>
  </div>
</template>

<script>



export default {
  name: 'faq',
  data() {
    return {
      faq: [
        {
          heading: 'What are your shipping charges like?',
          answer:
            'Sea freight starts from $8.00 <br> Air freight starts from $15.00. <br> You can find more details <a href="/freight-charges" target="_blank" rel="noopener noreferrer">here</a>',
        },
        {
          heading: 'What is the Sea Freight shipping lead time?',
          answer:
            'After the items arrive at our China warehouse, it takes around 2 - 3 weeks to deliver to your address.',
        },
        {
          heading: 'What is the Air Freight shipping lead time?',
          answer:
            'After the items arrive at our China warehouse, it takes around 4 - 7 working days to deliver to your address.',
        },
      ],
    }
  }, 
  mounted() {
    const toggles = document.querySelectorAll('.faq-toggle');

    toggles.forEach(toggle => {
      toggle.addEventListener('click', () => {
        toggle.parentNode.classList.toggle('active');
      });
    });
  },
}
</script>

<style scoped>

.faqs-container {
  margin: 0 auto;
  max-width: 600px;
  /* width: 40vw; */
  display: block;
}

.faq {
  background-color: transparent;
  border: 1px solid #9fa4a8;
  border-radius: 10px;
  padding: 30px;
  position: relative;
  overflow: hidden;
  margin: 20px 0;
  transition: 0.3s ease;
}

.faq.active {
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
}


.faq.active::before {
  color: #2408a1;
  top: -10px;
  left: -30px;
  transform: rotateY(180deg);
}

.faq-title {
  margin: 0 35px 0 0;
  font-weight: bold;
}

.faq-text {
  display: none;
  margin: 30px 0 0;
}

.faq.active .faq-text {
  display: block;
}

.faq-toggle {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0;
  position: absolute;
  top: 30px;
  right: 30px;
  height: 30px;
  width: 30px;
}

.faq-toggle:focus {
  outline: none;
}

.faq.active .faq-toggle {
  background-color: #9fa4a8;
}

.faq-toggle .fa-times {
  display: none;
}

.faq.active .faq-toggle .fa-times {
  display: block;
}

.faq-toggle .fa-chevron-down {
  color: #83888e;
}

.faq.active .faq-toggle .fa-chevron-down {
  display: none;
}
</style>
