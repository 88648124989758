<template>
  <div
    v-if="width >= '720'"
    class="container-fluid justify-content-center our-services mt-0"
    id="services"
  >
    <div class="overlay text-white">
      <div class="row mx-auto">
        <div class="col-lg-3 col-12 text-start py-0 about-us">
          <h2 id="about">Our Services</h2>
          <span class="smalltext">
            <p>
              Forwardier provides personalized sea/air freight services for clients who need help with shipping their goods from China to Singapore. 
            </p>
            <p>
              With our 1-to-1 customer service support, we strive to deliver a seamless experience for our clients by handling their logistical needs from China, right to their doorstep. 
            </p>
          </span>
        </div>
        <div
          class="d-flex col-md-3 col-12 my-auto pb-5 text-center"
          v-for="(service, index) of services"
          :key="index"
        >
          <div class="row mx-auto">
            <div class="col">
              <img :src="service.imgId" class="icon" />
              <p class="fw-bold pt-4 mb-0 text-uppercase">
                {{ service.heading }}
              </p>
              <p class="smalltext">
                {{ service.desc }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plane from "@/img/plane.svg";
import ship from "@/img/ship.svg";
import truck from "@/img/truck.svg";

export default {
  name: "services",
  data() {
    return {
      services: [
        {
          heading: "ORDERFORME",
          desc: "We help with liaising, purchasing and consolidating your orders",
          imgId: truck,
        },
        {
          heading: "FREIGHTFORME",
          desc: "Send your items to our warehouse and let us handle the logistical work",
          imgId: ship,
        },
        {
          heading: "SOURCEFORME",
          desc: "Finding the right suppliers for your business or event",
          imgId: plane,
        },
      ],
    };
  },
  props: ["width"],
};
</script>

<style scoped>
.our-services {
  padding: 4vw 0 0 1vw;
  margin: 48px auto 48px auto;
  max-width: 1142.89px;
  max-height: 100%;
  height: 404px;
  background: #333652;
  border-radius: 50px 50px 0px 0px;
  position: relative;
}


.our-services::before,
.our-services::after {
  position: absolute;
  content: "";
  width: 150px;
  height: 550px;
  background: #333652;
}

.our-services::before {
  position: absolute;
  width: 95.05px;
  height: 358.5px;
  left: -5%;
  top: 11.3%;
  clip-path: polygon(60.5% 0, 100% 0, 100% 100%, 0 100%);
}

.our-services::after {
  position: absolute;
  width: 95.05px;
  height: 358.5px;
  left: 96.7%;
  top: 11.3%;
  clip-path: polygon(0 0, 39.5% 0, 100% 100%, 0 100%);
}

#about {
  margin-top: -5vh;
  margin-bottom: -1vh;
}

.smalltext {
  font-size: 12.5px;
  padding: 10px;
  letter-spacing: 0.2px;
  text-align: justify;
  text-justify: inter-word;
}

.icon {
  max-width: 150px;
  max-height: 150px;
}

.about-us {
  padding: 30px;
}



/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 720px) {
/* Styles */
  .our-services {
    border-radius: 0 !important;
    height: 100%;
  }

  .our-services::before {
    display: none !important;
  }

  .our-services::after {
    display: none !important;
  }

  #about {
    margin-top: 0;
    margin-bottom: -1vh;
  }

  .about-us {
    padding: 10px !important;
  }
}



</style>
