<template>
  <div class="row text-center my-0 align-items-center section">
    <!-- <div class="col-lg-3 col-6" v-for="(logo, index) of logos" :key="index">
            <img :src="'@/img/' + logo['imgsrc']" class="logos" :alt="logo.name" />
        </div> -->
    <div v-for="logo in logos" :key="logo" class="col-lg-3 col-6">
      <img :src="logo" class="logos" alt="logo" />
    </div>
  </div>
</template>

<script>
import img1 from "@/img/naturalcool.png";
import img2 from "@/img/ace.png";
import img3 from "@/img/yihong.png";
import img4 from "@/img/smu.png";

export default {
  data: function () {
    return {
      logos: [img1, img2, img3, img4],
    };
  },
};
</script>

<style scoped>
.logos {
  max-width: 12rem;
  margin: 2rem 5rem;
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 720px) {
/* Styles */
  .logos {
    max-width: 8rem;
    margin: 2rem 1rem !important;
  }
}
</style>
