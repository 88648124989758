<template>
    <Header />

    <div class="row d-flex mx-auto justify-content-center mb-5 cta animated animatedFadeInUp fadeInUp">
        <div class="col-lg-4 col-12 my-auto">
          <h1 class="bold_title">{{ title }}</h1>
               <p v-if="errors.length">
                      <b>Please correct the following error(s):</b>
                      <ul>
                          <li v-for="error in errors">{{ error }}</li>
                      </ul>
                  </p>
          <form @submit.prevent="handleLogin" method="POST" :class="{mx_form: formInv}">
                    <div class="txt_field">
                        <input type="text" name="username" id="username" v-model="username" required>
                        <label for="">Username</label>
                    </div>
                    <small>Fill in the username</small>
                    <div class="txt_field">
                        <input type="password" name="password" id="password" v-model="password" required>
                        <label for="">Password</label>
                    </div>
                    <small>Fill in the password</small>
                    <div class="row" style="margin-top: -5%;">
                        <div class="col md-6">
                            <div class="remember_password">
                                <input type="checkbox" id="checkbox" v-model="checkbox">
                                <label for="checkbox"></label>
                                <span>Remember Me</span>
                            </div>
                        </div>
                        <div class="col md-6">
                           <div class="pass float-end"><a href="">Forgot Password</a></div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col md-12 mx-4">
                            <button class="button-wom my-5">Login To Your Account</button>
                        </div>
                    </div>
                    
                    
                    <div class="signup">Don't have an account? <a href="/signup" class="font-weight-bold">Sign Up!</a></div>
                </form>
        </div>
        <div class="col-lg-4 col-12 my-auto">
            <img src="@/../img/login.png" style="max-width: 100%;" />
        </div>


    <Footer />
  </div>
</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { useMeta } from 'vue-meta';

export default {
  name: "login",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
        title: 'Login to Your Account!',
        username: '',
        password: '',
        errors: [], 
        checkbox: null,
        formInv: false,
        browserW: window.innerWidth,
    };
  },
  setup () {
    useMeta({ title: 'Login' })
  }, 
   methods: {
        handleLogin() {
          this.errors = []
          const credentials = {
            username: this.username,
            password: this.password
          }
  
          if (this.checkbox) {
              localStorage.username = this.username;
              localStorage.checkbox = this.checkbox;
          } else {
              localStorage.clear();
          }
  
          if(this.username && this.password) {
              axios
              .post(encodeURI(API_URI + "/login"), credentials)
              .then((response) => {
                if(response.data.token) {
                  sessionStorage.setItem('token', response.data.token)
                  sessionStorage.setItem('role', response.data.user.role)
                  sessionStorage.setItem('username', response.data.user.username)
                  setAuthHeader(response.data.token)
                    this.goToDashboard();
                }
                
                if(response.data.body.message) {
                    this.errors.push(response.data.body.message)
                }
      
              })
              .catch((err) => console.log(err));
          } else {

            this.formInv = true

        }
        },
        goToDashboard() {
          const accountIdentity = sessionStorage.getItem("role");
          if (accountIdentity == "learner") {
              window.location.replace("learner/dashboard.html");
          } else if (accountIdentity == "mentor") {
              window.location.replace('mentor/mentor-dashboard.html');
          } else if (accountIdentity == "admin") {
              window.location.replace('admin/admin-dashboard.html');
          }
      }
    },
  mounted() {
    
  }, 
  created() {
      
      if (localStorage.checkbox) {
          this.username = localStorage.username;
          this.checkbox = localStorage.checkbox;
      }
    }
};
</script>

<style>
a:link {
  color: black;
  text-decoration: none;
}

a:visited {
  color:black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: underline;
}

p,
span {
  font-family: "Red Hat Display", sans-serif;
}

.home {
  margin-top: 15vh;
}
/* Login CSS */

.textbox {
  width: 339px;
  height: 51px;
  background: #ffffff;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
}

form .txt_field {
  position: relative;
  border-bottom: 2px solid #000000;
  margin: 50px 0;
}

form .dropdown {
  position: relative;
  border-bottom: 2px solid #000000;
  margin: 50px 0;
}


.error-check {
  margin-top: -10px;
}

.bold_title {
  margin-top: 5%;
  margin-bottom: 10%;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.txt_field input, .txt_field select {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  border: none;
  outline: none;
}

.txt_field label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  transform: translateY(-50%);
  font-size: 18px;
  pointer-events: none;
  transition: 0.5s;
  text-overflow: ellipsis;
  overflow: hidden; 

}


.main_text {
  position: relative;
  margin-top: -20px;
  margin-bottom: -40px;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  pointer-events: none;
  transition: 0.5s;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.txt_field span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #000000;
  transition: 0.5s;
}

.txt_field input:focus ~ label,
.txt_field input:valid ~ label,
.txt_field select:focus ~ label,
.txt_field select:valid ~ label  {
  top: -20px;
  color: #000000;
}

.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before,
.txt_field select:focus ~ span::before,
.txt_field select:valid ~ span::before {
  width: 100%;
}

.remember_password {
  position: relative;
}

.remember_password label {
  background-color: #fff;
  border: 2px solid #a6a6a6;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}
.remember_password span {
  margin-left: 1em;
}

.remember_password label:after {
  border: 2px solid #ffffff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 12px;
}

.remember_password input[type="checkbox"] {
  visibility: hidden;
}

.remember_password input[type="checkbox"]:checked + label {
  background-color: #000000;
  border-color: #000000;
}

.remember_password input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.mobile-v {
  width: 100%;
}

#button {
  width: 205px;
  height: 60px;
  left: calc(50% - 205px / 2 - 292.5px);
  padding: 1rem 0;
  background: #1e1e1e;
  color: white;
}

.button-wom {
  width: 283px;
  height: 69px;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 140.62%;
  color: #ffffff;
  background: #161616;
  border: 1px solid black;
  border-radius: 13px;
  box-shadow: -6px -6px 0 0 white, -6px -6px 0 2px black;
  display: inline-block;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus, select:focus {
  -webkit-box-shadow: none;
  outline: -webkit-focus-ring-color auto 0px;
}

small {
	color: red;
	display: none;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 990px) {
/* Styles */
  .cta {
    display: none;
  }

  .mobile-v {
    display: none;
  }
}

</style>
